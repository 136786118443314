import React from 'react';
import {
    translate,
    SelectInput,
    SelectArrayInput,
} from 'react-admin';
import compose from 'recompose/compose';
import ChipList from '../../../../components/ChipList';

import resourceLocales from './locales';
import resourceConfig from './config';
import resourceData from './data';


const ResourceField = compose(translate)(({ translate, source, record = {}}) => (
  <span>
    {record[source] && translate(`resources.${resourceConfig.name}.enums.${record[source]}`)}
  </span>
));
ResourceField.defaultProps = {
  addLabel: true
};

const ResourceArrayField = compose(translate)(({ translate, source, record = {}}) => (
  <ChipList
    items={record[source] && record[source].map(x => (translate(`resources.${resourceConfig.name}.enums.${x}`)))}
  />
));
ResourceArrayField.defaultProps = {
  addLabel: true
};

const ResourceInput = compose(translate)(({ translate, ...rest }) => (
  <SelectInput
    {...rest}
    choices={resourceData.enums.map(x => ({
        id: x.id,
        name: translate(`resources.${resourceConfig.name}.enums.${x.id}`),
    }))}
  />
));

const ResourceArrayInput = compose(translate)(({ translate, ...rest }) => (
  <SelectArrayInput
    {...rest}
    choices={resourceData.enums.map(x => ({
        id: x.id,
        name: translate(`resources.${resourceConfig.name}.enums.${x.id}`),
    }))}
  />
));



export default {
    Field: ResourceField,
    ArrayField: ResourceArrayField,
    Input: ResourceInput,
    ArrayInput: ResourceArrayInput,
    
    name: resourceConfig.name,
    locales:  resourceLocales,
}
