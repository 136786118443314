import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        EDIT: 'EDIT',
        CREATE: 'CREATE',
      },
    },
  },
};
