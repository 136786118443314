

export default {
  enums: [
    { id: 'DRAFT' },
    { id: 'REJECTED' },
    { id: 'W_VERIFICATION' },
    { id: 'ACCEPTED' },
    { id: 'CHECK_ERROR' },
    { id: 'WITHDRAWN' },
    { id: 'CANCELED' },
    { id: 'СULC_RESULTS' },
    { id: 'CLARIFICATION_REQUESTED' },
    { id: 'FORMED' },
    { id: 'IN_PROGRESS' },
    { id: 'CHECKED_OK' },
    { id: 'CHECKED_NOT_OK' },
    { id: 'ERROR'},
    { id: 'SENDED'},
    { id: 'SENDING_ERROR'},
    { id: 'ACCEPTANCE_ERROR'}
  ]
};